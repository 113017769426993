<template>
  <div>
    <el-row style="background-color: #ffffff; padding-left: 20px">
      <content-title :nav="nav"></content-title>
    </el-row>
    <div class="UserFacts">
      <div class="Facts">
        <img class="img" src="../../assets/yh1.png" alt="" />
        <div class="div-p">
          <p class="p-1">{{ total }}</p>
          <p class="p-2">商户总数</p>
        </div>
      </div>
      <div class="Facts">
        <img class="img" src="../../assets/yh2.png" alt="" />
        <div class="div-p">
          <p class="p-1">0</p>
          <p class="p-2">今日新增</p>
        </div>
      </div>
    </div>
    <div class="nr-box">
      <el-form :inline="true" :model="Searchlist" class="demo-form-inline">
        <el-form-item label="商家名称">
          <el-input v-model="mechanism_name"></el-input>
        </el-form-item>
        <el-form-item label="负责人电话">
          <el-input v-model="mechanism_telephone"></el-input>
        </el-form-item>
        <el-form-item :required="true" label="商户类型">
          <el-select v-model="searchType" placeholder="请选择商户类型">
            <el-option label="商户" value="商户"></el-option>
            <el-option label="机构商户" value="机构商户"></el-option>
          </el-select>
        </el-form-item>
        <label> 状态 </label>
        <el-select v-model="mecstatus" placeholder="请选择" clearable>
          <el-option label="待审核" value="1"> </el-option>
          <el-option label="已通过" value="2"> </el-option>
          <el-option label="已拒绝" value="3"> </el-option>
        </el-select>
        <el-form-item>
          <el-button
            @click="Searchclick"
            style="background-color: #ff7e00; color: #ffffff; border: 0"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
      <el-button
        type="warning"
        @click="openAdd"
        class="blt"
        plain
        >添加商户</el-button
      >

      <el-table ref="multipleTable" :data="recording" tooltip-effect="dark">
        <el-table-column prop="id" align="center" label="id"></el-table-column>
        <el-table-column
          prop="mechanism_name"
          align="center"
          label="商户名称"
        ></el-table-column>
        <el-table-column
          prop="mechanism_telephone"
          align="center"
          label="商户电话"
        ></el-table-column>
        <el-table-column
          prop="categories"
          align="center"
          label="商户类别"
        ></el-table-column>
        <!-- <el-table-column
            :formatter="fromTitle"
            prop="payee_logon_id"
            align="center"
            label="支付宝账号"
          ></el-table-column> -->
        <el-table-column prop="mechanism_addr" align="center" label="机构地址">
          <template slot-scope="scope">
            <el-popover trigger="hover" placement="top">
              <p>地址: {{ scope.row.mechanism_addr }}</p>
              <div slot="reference" class="name-wrapper">
                <el-tag size="medium">机构地址</el-tag>
              </div>
            </el-popover>
          </template></el-table-column
        >
        <!-- <el-table-column
            :formatter="fromTitle"
            prop="contacts"
            align="center"
            label="负责人姓名"
          ></el-table-column>
          <el-table-column
            :formatter="fromTitle"
            prop="contact_information"
            align="center"
            label="负责人电话"
          ></el-table-column> -->
        <el-table-column
          prop="contact_telephone"
          align="center"
          label="主账号"
        ></el-table-column>
        <el-table-column
          prop="create_time"
          align="center"
          width="200"
          label="录入时间"
        ></el-table-column>
        <el-table-column prop="status" label="状态" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 1" style="color: #23c3c4"
              >待审核</span
            >
            <span v-else-if="scope.row.status == 2" style="color: #b8b8b8"
              >已通过</span
            >
            <span v-else-if="scope.row.status == 3" style="color: #ff6262"
              >已拒绝</span
            >
            <span v-else-if="scope.row.status == 4" style="color: #ff6262"
              >注销</span
            >
            <span v-else-if="scope.row.status == 5" style="color: #ff6262"
              >取消</span
            >
            <span v-else style="color: #ff6262">冻结</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <div style="text-align: left">
               <span
                @click="updateInfo(scope.row)"
                style="margin-right: 10px; color: #3cb15f"
                >编辑</span
              >
              <span
                @click="DetailsPage(scope.row)"
                style="margin-right: 10px; color: #ff7e00"
                >查看详情</span
              >
              <span
                @click="upstatus(scope.row)"
                v-if="scope.row.status != 2"
                style="color: #f56c6c"
                >上架</span
              >
              <span
                @click="upstatus(scope.row)"
                v-if="scope.row.status == 2"
                style="color: #f5666c"
                >下架</span
              >
              <div
                @click="showCode(scope.row)"
                style="text-align: left; color: #ff7e00"
              >
                查看二维码
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <paging-fy
        @currentPageChange="handleCurrentChange"
        :currentPage="currentPage"
        :total="total"
      ></paging-fy>
    </div>


    <el-dialog
      class="dialog"
     :title="mode == 'edit' ? '修改商户' : '新增商户'"
      :visible.sync="dialogVisible"
      :center="true"
      width="1045px"
    >
      <el-form
        id="dialogfrom"
        ref="form"
        class="dialogfrom"
        :model="form"
        label-width="110px"
      >
        <div class="flex">
          <div>
            <el-form-item :required="true" label="商户名称">
              <el-input v-model="form.mechanism_name"></el-input>
            </el-form-item>
            <el-form-item :required="true" label="商户类型">
              <el-select v-model="Mectype" placeholder="请选择商户类型">
                <el-option label="商户" value="商户"></el-option>
                <el-option label="机构商户" value="机构商户"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item :required="true" label="是否分店" v-if="mode == 'add'">
              <el-switch v-model="is_sub" active-text="是" inactive-text="否">
              </el-switch>
            </el-form-item>
            <el-form-item v-if="is_sub" label="所属机构" key="education_id">
              <el-select
                v-model="form.education_id"
                :remote-method="getMechanismList"
                remote
                filterable
                placeholder="请选择机构"
              >
                <el-option
                  v-for="item in mechanism_list"
                  :key="item.id"
                  :label="item.mechanism_name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item :required="true" label="选择位置">
               <addr-select
              :clear="if_clear"
              @getform="addMecAddr"
            ></addr-select>
            </el-form-item>
             <el-form-item label="省市区" required>
            <city-select @change="changeCity" :temp="cityList"></city-select>
          </el-form-item>
          <el-form-item label="详细地址" required>
            <el-input
              v-model="form.mechanism_addr"
              placeholder="请输入地址"
            ></el-input>
          </el-form-item>
            <!-- <el-form-item label="商户经度">
              <el-input v-model="form.longitude"></el-input>
            </el-form-item>
            <el-form-item label="商户纬度">
              <el-input v-model="form.latitude"></el-input>
            </el-form-item> -->
            <el-form-item label="机构电话">
              <el-input v-model="form.mechanism_telephone"></el-input>
            </el-form-item>
            <el-form-item :required="true" label="负责人职位">
              <el-input v-model="form.contacts_title"></el-input>
            </el-form-item>
            <el-form-item :required="true" label="负责人姓名">
              <el-input v-model="form.contacts"></el-input>
            </el-form-item>
            <el-form-item :required="true" label="负责人电话" v-if="!is_sub">
              <el-input v-model="form.contact_telephone"></el-input>
            </el-form-item>
            <el-form-item label="支付宝账号">
              <el-input v-model="form.payee_logon_id"></el-input>
            </el-form-item>
            <!-- <el-form-item label="绑定用户">
              <el-select
                v-model="selected_user"
                placeholder="请选择"
                value-key="user_id"
                filterable
                style="margin-top: 10px"
                @change="changeuser"
                remote
                :remote-method="getUser"
              >
                <el-option
                  v-for="item in userList"
                  :key="item.user_id"
                  :label="item.nick_name"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item label="商户简介">
              <el-input
                type="textarea"
                :rows="4"
                placeholder="请输入内容"
                v-model="form.introduction_content"
              >
              </el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="核销限制">
              <el-input v-model="form.restrictions_count"></el-input>
            </el-form-item>
            <el-form-item
              :required="true"
              :hide-required-asterisk="true"
              label="商户类别"
            >
              <el-select
                v-model="region"
                value-key="id"
                multiple
                placeholder="请选择商户类别"
              >
                <el-option
                  v-for="(item, index) in ListPageChild"
                  :key="index"
                  :label="item.name"
                  :value="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              :required="true"
              :hide-required-asterisk="true"
              label="商户子类别"
            >
              <el-select
                multiple
                v-model="form.categories_child"
                placeholder="请选择商户子类别"
              >
                <el-option
                  :label="item.name"
                  :value="item.name"
                  v-for="(item, index) in ListPageChild2"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :required="true" label="商户标志logo">
              <avatar-uploader
                @getImgUrl="handleAvatarSuccessscbz"
                :clear="if_clear"
                :url="form.mechanism_logo"
              ></avatar-uploader>
              <!-- 上传logo -->
            </el-form-item>
            <el-form-item :required="true" label="营业执照">
              <avatar-uploader
                @getImgUrl="handleAvatarSuccesszmcl"
                :clear="if_clear"
                :url="form.support_means"
              ></avatar-uploader>
            </el-form-item>
            <el-form-item :required="true" label="商户资质">
              <avatar-uploader
                @getImgUrl="handleAvatarSuccessjgzz"
                :clear="if_clear"
                :url="form.qualifications_pic"
              ></avatar-uploader>
            </el-form-item>
            <el-form-item :required="true" label="简介图片">
             <images-uploader
                @getImgUrl="handleAvatarSuccessjjtp"
                :urls="form.introduction_pic"
                :clear="if_clear"
              >
              </images-uploader>
            </el-form-item>
            <el-form-item :required="true" label="商户门面照">
              <avatar-uploader
                @getImgUrl="handleAvatarSuccess"
                :clear="if_clear"
                :url="form.facade_view"
              ></avatar-uploader>
            </el-form-item>
          </div>
        </div>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" class="butt" v-if="mode == 'edit'" @click="updateForm">修 改</el-button>
          <el-button type="primary" class="butt" v-else @click="add">添 加</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :visible="codedialogVisible"
      title="机构二维码"
      :before-close="handleClose"
    >
      <div class="flex al jc-c">
        <vue-qr ref="Qrcode" :text="dataObj.text" qid="testQrId"></vue-qr>
      </div>
      <div class="flex al jc-c">
        <a
          class="load"
          :href="exportLink"
          @click="downloadImg"
          :download="downloadFilename"
          >下载二维码</a
        >
      </div>
      <span slot="footer">
        <el-button @click="codedialogVisible = false" type="primary"
          >确认</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import mapdemo from "@/components/map.vue";
import VueQr from "vue-qr";
import { ObjectChangedValue } from "@/js/utils.js";
import citySelect from "@/components/select/citySelect.vue";
export default {
  name: "MerchantList",
  components: {
    mapdemo: mapdemo,
    VueQr,
    citySelect
  },
  data() {
    return {
      if_clear: false,
      Addmerchantlist: {
        name: "",
        address: "",
        personincharge: "",
        Telephone: "",
        remarks: "",
        longitude: "", //经度
        latitude: "", //玮度
      },
      Searchlist: {
        value1: "",
        value2: "",
        value3: "",
      },
      tableData: [],
      activeName: 0,
      tabslist: ["未入驻", "已入驻"],
      nav: {
        firstNav: "商户管理",
        secondNav: "商户管理",
      },
      dialogmap: false,
      props: {
        label: "name",
        children: "zones",
      },
      originObj:{},
      is_sub: false,
      region: [],
      listPic: [],
      ListPageChild: [],
      ListPageChild2: [],
      recording: [], //精品课程
      pageSize: 10,
      currentPage: 1,
      total: 0,
      NumData: {
        total: 0, //认证总数
        sportCount: 0,
        elseCount: 0,
        sportCount: 0,
        subjectsCount: 0,
        puzzleCount: 0,
      },
      adopt: 0,
      stay_num: 0,
      refuse_num: 0,
      dialogVisible: false,
      dialogVisible1: false,
      mechanism_name: "",
      mechanism_telephone: "",
      form: {
        type: "商户",
        mechanism_language: "中文",
        is_support_teach_paypal: true,
      },
      // 图片上传
      domain: "http://up-z0.qiniup.com",
      upload_qiniu_addr: "http://img.curiousmore.com/",
      QiNiYunL: {
        key: "",
        token: "",
      },
      userList: [],
      selected_user: "",
      curuser: {},
      mecstatus: "2",
      dataObj: {},
      downloadFilename: "",
      exportLink: "",
      codedialogVisible: false,
      Mectype: "商户",
      searchType: "机构商户",
      cityoptions: [],
      cityProps: {
        value: "district",
        label: "district",
        children: "childrens",
      },
      cityList: [],
      mechanism_list: "",
      mode:'add'
    };
  },
  mounted() {
    this.GetList();
    this.gettoken();
    this.logbut();
    this.GetListPageChild();
  },
  methods: {
    openAdd(){
       this.mode = 'add'
       this.form = {}
       this.if_clear = false
        this.if_clear = true
         this.region = []
        this.cityList = []
      this.dialogVisible = true
     
    },
    updateInfo(row){
        this.originObj = Object.assign({},row)
        this.form = row
        this.mode = 'edit'
        if(row.parent_id > 0){
          this.is_sub = true
        }
        this.Mectype = row.type
        this.cityList = []
        this.region = this.form.categories.split(',')
        this.$set(this.form,"categories_child",row.categories_child.split(','))
        this.cityList.push(row.province);
          this.cityList.push(row.city);
          this.cityList.push(row.district);
        this.dialogVisible = true
    },
     // 新增机构地址
    addMecAddr(addr) {
      console.log(addr);
      this.$set(this.form, "province", addr.province);
      this.$set(this.form, "city", addr.city);
      this.$set(this.form, "district", addr.district);
      this.$set(this.form, "latitude", addr.addrPoint.lat);
      this.$set(this.form, "longitude", addr.addrPoint.lng);
      this.$set(this.form, "mechanism_addr", addr.address);
      this.cityList.push(addr.province);
      this.cityList.push(addr.city);
      this.cityList.push(addr.district);
    },
      changeCity(v) {
      this.$set(this.form, "province", v[0]);
      this.$set(this.form, "city", v[1]);
      this.$set(this.form, "district", v[2]);
      this.cityList = v;
    },
    getMechanismList(query) {
      let url = "/user/mastermechanism/queryByMessage";
      this.$axios
        .get(url, {
          params: {
            type: this.Mectype,
            status: 2,
            // is_cooperation: true,
            currentPage: 1,
            pageSize: 10,
            mechanism_name: typeof query == "string" ? query : null,
            // parent_id: 0,
            // source:'体育宝',
          },
        })
        .then((res) => {
          // console.log(res.data);
          this.mechanism_list = res.data.data.rows;
        })
        .catch((rej) => {});
    },

    downloadImg() {
      let Qrcode = this.$refs.Qrcode;
      this.exportLink = Qrcode.$el.currentSrc;
      this.downloadFilename = "二维码";
    },
    showCode(row) {
      let that = this;
      this.codedialogVisible = true;
      let type = row.type == "商户" ? "商户" : "机构";
      let info = `id:${row.id},type:${type}`;
      this.dataObj = {
        text: info,
      };
    },
    DetailsPage(row) {
      this.$router.push({
        name: "MerchantDetail",
        query: { id: JSON.stringify(row.id) },
      });
    },
    //搜索点击
    Searchclick() {
      this.GetList();
    },
    //TAB 点击触发
    handleClicktab() {},
    //查询用户信息的mechanism_id，如果已绑定机构，则不让创建机构
    changeuser(e) {
      this.curuser = e;
    },
    getUser(query) {
      this.user_list = [];
      if (query && typeof query == "string") {
        let url = "/user/userInfo/queryByMessage";
        this.$axios
          .get(url, {
            params: {
              mobile: query,
            },
          })
          .then((res) => {
            this.userList = res.data.data.rows;
          });
      }
    },
    logbut() {
      let url =
        "/user/mechanismCategory/queryListPageChild?&type=2&status=2&source=商户";
      this.$axios.get(url).then((res) => {
        this.ListPageChild2 = res.data.data;
      });
    }, //获取父类目
    GetListPageChild() {
      let url =
        "/user/mechanismCategory/queryListPageChild?&type=1&status=2&source=商户";
      this.$axios.get(url).then((res) => {
        this.ListPageChild = res.data.data;
      });
    }, //子类目
    updateForm(){
      if (this.cityList.length > 0) {
        this.form.province = this.cityList[0];
        this.form.city = this.cityList[1];
        this.form.district = this.cityList[2];
      }
      this.formcategories = this.region.toString()
         this.formcategories_child = this.form.categories_child.toString()
         const data = ObjectChangedValue(this.originObj, this.form);
      if (Object.keys(data).length == 0) {
        this.$message("没有修改数据，无需更新");
        return;
      }

      let url = "/user/mastermechanism/update";
      this.$axios
        .post(url, {
            id:this.form.id,
          ...data
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.dialogVisible = false
            this.$message({ type: "warning", message: "修改成功!" });
          }
        });
    },
    // 添加商户
    add() {
      this.form.province = this.cityList[0];
      this.form.city = this.cityList[1];
      this.form.district = this.cityList[2];
      this.form.restrictions_count =
        this.form.restrictions_count == "" ? 10 : this.form.restrictions_count;
      // let url = "/user/mastermechanism/insertShop";
      let url = "/user/mastermechanism/insertAppointmentMechanismInfo";
      
      if (this.is_sub) {
        url = "/user/mastermechanism/insertAppointmentMechanismInfoBranches";
        this.Mectype = this.Mectype+"分店";
        // this.Mectype = "分店";
      }

      let data = {
        ...this.form,
        // restrictions_count, //购买限制
        categories: this.region.toString(),
        categories_child: this.form.categories_child.toString(),
        status: 2,
        // type:'merchants',
        type: this.Mectype,
      };
      this.$axios.post(url, data).then((res) => {
        // alert(res.data.message);
        this.Mectype = ''
        this.GetList();
        this.dialogVisible = !this.dialogVisible;
      });
    },
    onSubmit() {
      //查询
      if (this.mechanism_name == "" && this.mechanism_telephone == "") {
        this.GetList();
      } else {
        var url =
          "/user/mastermechanism/queryByMessage?type=商户&mechanism_name=" +
          this.mechanism_name +
          "&mechanism_telephone=" +
          this.mechanism_telephone;
        this.$axios.get(url).then((res) => {
          this.recording = res.data.data.rows;
          this.total = res.data.data.total;
        });
      }
    },

    fromTitle(row, column, cellValue) {
      if (cellValue === "") {
        return "暂无";
      } else {
        return cellValue;
      }
    },
    handleClick(row) {
      this.$router.push({
        name: "MerchantDetail",
        query: { id: JSON.stringify(row.id) },
      });
    }, //详情
    upstatus(e) {
      let url = "/user/mastermechanism/update";
      let data = {
        id: e.id,
        status: e.status == 2 ? 3 : 2,
      };

      this.$axios.post(url, data).then((res) => {
        this.GetList();
      });
    }, // 推荐
    filterChange(filterObj) {
      // console.log(filterObj);
    },
    //列表
    GetList() {
      const params = {
        type: this.searchType,
        pageSize: 10,
        currentPage: this.currentPage,
        status: this.mecstatus,
      };
      if (this.mechanism_name != "") {
        params.mechanism_name = this.mechanism_name;
      }
      if (this.mechanism_telephone != "") {
        params.mechanism_telephone = this.mechanism_telephone;
      }
      this.$axios({
        method: "get",
        url: "/user/mastermechanism/queryManagerListPage",
        params,
      })
        .then((res) => {
          this.recording = res.data.data.rows;
          this.total = res.data.data.total;
        })
        .catch((err) => {});
    },
    //分页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.GetList();
    },
    //图片
    // 获取token
    gettoken() {
      let _this = this;
      var timestamp = new Date().getTime();
      let data = {
        key: timestamp,
        type: "pic",
      };
      _this
        .$axios({
          method: "post",
          url: "http://101.37.66.151:8768/eg-api/push/upload/getToken",
          data: data,
        })
        .then((res) => {
          _this.QiNiYunL.token = res.data.data;
          _this.QiNiYunL.key = data.key;
        })
        .catch((err) => {});
    },
    handleCurrentChangeto(val) {
      this.$router.push({
        name: "Selectcourse",
        query: { id: JSON.stringify(val), events: this.$route.query.id },
      }); //拿到活动，选择商户，跳转+课程
    },
    handleClose(done) {
      this.codedialogVisible = false;
    },
    handleAvatarSuccess(file) {
      this.form.facade_view = file;
    },
    handleAvatarSuccessjjtp(file) {
      this.form.introduction_pic = file;
    },
    handleAvatarSuccessscbz(file) {
      this.form.mechanism_logo = file;
    },
    handleAvatarSuccesszmcl(file) {
      this.form.support_means = file;
    },
    handleAvatarSuccessjgzz(file) {
      this.form.qualifications_pic = file;
    },
    beforeAvatarUpload(file) {
      this.gettoken();
    },
  },
};
</script>

<style lang="less">
.UserFacts {
  display: flex;
  background-color: #ffffff;
  padding: 10px 20px 20px 20px;
  flex-wrap: wrap;
  .Facts {
    display: flex;
    width: 220px;
    height: 72px;
    background: #ffffff;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.07);
    box-sizing: border-box;
    padding: 17px 0 14px 30px;
    margin-right: 50px;
    .img {
      width: 34px;
      height: 38px;
      margin-right: 20px;
    }
    .div-p {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-size: 14px;
      .p-1 {
        color: #434343;
      }
      .p-2 {
        color: #808080;
      }
    }
  }
}
.nr-box {
  background-color: #ffffff;
  margin-top: 20px;
  padding: 30px 20px 0 20px;
  .ckys {
    background-color: #ff7e00;
    color: #ffffff;
  }
  .ckys1 {
    color: #f56c6c;
  }
}

.el-form-item {
  margin-right: 43px !important;
}
.cell {
  text-align: center;
}
.load {
  margin-top: 20px;
  color: rgb(96, 186, 238) !important;
}
</style>
